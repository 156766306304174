// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
import './lib/audioplayer.js';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import 'lite-youtube-embed/src/lite-yt-embed.js';
import 'details-element-polyfill';
import imagesLoaded from 'imagesLoaded';
import Masonry from 'masonry-layout';
import './nav.js';
import {Luminous, LuminousGallery} from 'luminous-lightbox';
import Ready from './lib/ready.js';

gsap.registerPlugin(ScrollTrigger);

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}

Ready(() => {
  const tl = gsap.timeline({repeat: -1});

  tl.set('.vinyl-record-tracks', {transformOrigin: 'center'});
  tl.to('.vinyl-record-tracks', {rotate: 360, duration: 1.8, ease: 'none'});

  const galleryContainer = document.querySelectorAll(
    '[data-page="gallery"] .gallery-container',
  );
  if (galleryContainer) {
    [...galleryContainer].forEach((container) => {
      imagesLoaded(container, () => {
        new Masonry(container, {
          // options
          itemSelector: '.gallery-item',
          columnWidth: '.gallery-sizer',
          percentPosition: true,
        });
      });
    });
  }

  new LuminousGallery(document.querySelectorAll('.gallery-item'));

  const animateUps = gsap.utils.toArray(
    '.star, .main-content>*:not(.bg-nudge)',
  );

  const mm = gsap.matchMedia();

  mm.add(
    '(min-width: 100px) and (prefers-reduced-motion: no-preference)',
    () => {
      gsap.set(animateUps, {y: 40, autoAlpha: 0});
      ScrollTrigger.batch(animateUps, {
        onEnter: (elements, triggers) =>
          gsap.to(elements, {
            y: 0,
            autoAlpha: 1,
            duration: 1.6,
            ease: 'power3.out',
            stagger: 0.15,
          }),
      });
    },
  );
});
